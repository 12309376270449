import Vue from 'vue'
import 'vant/lib/index.css'

import { Button } from 'vant'
import { Divider } from 'vant'
import { Checkbox, CheckboxGroup } from 'vant'
import { Popup } from 'vant'
import { Icon } from 'vant'
import { Collapse, CollapseItem } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import { ActionSheet } from 'vant'
import { DatetimePicker } from 'vant'
import { RadioGroup, Radio } from 'vant'
import { Uploader } from 'vant'
import { Stepper } from 'vant'
import { Rate } from 'vant'
import { Step, Steps } from 'vant'
import { Toast } from 'vant'
import { Empty } from 'vant'
import { Dialog } from 'vant'
import { Loading } from 'vant'
import { Slider } from 'vant'
import { Cell, CellGroup } from 'vant'
import { Image as VanImage } from 'vant'
import { Lazyload } from 'vant'
import { Calendar } from 'vant'
import { Popover } from 'vant'

Vue.use(Button)
Vue.use(Divider)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(ActionSheet)
Vue.use(DatetimePicker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Uploader)
Vue.use(Stepper)
Vue.use(Rate)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Slider)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(VanImage)
Vue.use(Lazyload)
Vue.use(Calendar)
Vue.use(Popover)