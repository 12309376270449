import myAxios from './request';

const api = {
	// 全局
	getHomeBase: (params)=> myAxios({url: "cms/home/base", method: 'get', params}, {message: 'loading', forbidClick: true}),																		//  获取页面公共数据
	uploads: (data)=> myAxios({url: "uploads/file/image", method: 'post', data}, {message: 'uploads', forbidClick: true}, {code_message_show: true}),												//  上传图片
	uploadsBase: (data)=> myAxios({url: "uploads/file/base64-image", method: 'post', data}),																					                    //  上传base64图片
	getPolicyinfo: (params)=> myAxios({url: "cms/home/policyinfo", method: 'get', params}),																						                    //  获取政策信息
	
	// 注册
	getRegisterIndex: (params)=> myAxios({url: "customer/register/index", method: 'get', params}),																				                    //  注册初始化
	getRegisterCode: (params)=> myAxios({url: "customer/site/code-by-register", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),				        //  获取注册验证码
	getSmsCode: (params)=> myAxios({url: "customer/site/sms-by-register", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),								//  获取手机注册验证码
	registerAccount: (data)=> myAxios({url: "customer/register/account", method: 'post', data}, {}, {code_message_show: true}),													                    //  注册数据提交
	
	// 登录
	getloginIndex: (params)=> myAxios({url: "customer/login/index", method: 'get', params}),																					                    //  登录初始化
	loginAccount: (data)=> myAxios({url: "customer/login/account", method: 'post', data}, {message: 'LOG IN...', forbidClick: true}, {code_message_show: true}),									//  登录提交
	loginFacebook: (params)=> myAxios({url: "customer/facebook/loginv", method: 'get', params}, {message: 'LOG IN...', forbidClick: true}, {code_message_show: true}),								//  FB登录回调
	loginGoogle: (params)=> myAxios({url: "customer/google/loginv", method: 'get', params}, {message: 'LOG IN...', forbidClick: true}, {code_message_show: true}),									//  Google登录回调
	
    // 订阅
	setSubscribe: (params)=> myAxios({url: "customer/newsletter/subscribe", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),                            //  订阅

	// 忘记密码
	getForgotCode: (params)=> myAxios({url: "customer/site/code-by-forgot", method: 'get', params}, {}, {code_message_show: true}),												                    //  发送验证码
	forgotSubmit: (data)=> myAxios({url: "customer/forgot/submitresetpassword", method: 'post', data}, {}, {code_message_show: true}),											                    //  数据提交
	
	// 地址
	editAddressIndex: (params)=> myAxios({url: "customer/address/edit", method: 'get', params}, {message: 'loading', forbidClick: true}),															//  编辑地址初始化
	addressChangecountry: (params)=> myAxios({url: "customer/address/changecountry", method: 'get', params}, {message: 'loading', forbidClick: true}),												//  切换国家
	addressSave: (data)=> myAxios({url: "customer/address/save", method: 'post', data}, {message: 'save...', forbidClick: true}, {code_message_show: true}),										//  地址提交
	addressRemove: (params)=> myAxios({url: "customer/address/remove", method: 'get', params}, {message: 'remove...', forbidClick: true}),															//  地址删除
	getAddressIndex: (params)=> myAxios({url: "customer/address/index", method: 'get', params}, {message: 'loading', forbidClick: true}),															//  地址列表
	
	// 个人中心
	getUserInfo: (params)=> myAxios({url: "customer/editaccount/info", method: 'get', params}),																					                    //  我的信息
	updateInfo: (data)=> myAxios({url: "customer/editaccount/updateinfo", method:'post', data}, {message: 'save...', forbidClick: true}, {code_message_show: true}),								//  我的信息更新
	editAccountUpdate: (data)=> myAxios({url: "customer/editaccount/update", method: 'post', data}, {message: 'save...', forbidClick: true}, {code_message_show: true}),							//  账号信息更新
	getOrder: (params)=> myAxios({url: "customer/order/index", method: 'get', params}, {message: 'loading', forbidClick: true}),																	//  订单列表
	getOrderView: (params)=> myAxios({url: "customer/order/view", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),										//  订单详情
	getSalesAfter: (params)=> myAxios({url: "checkout/sales-after/index", method: 'get', params}, {message: 'loading', forbidClick: true}),														    //  售后申请初始化
	salesAfterSave: (data)=> myAxios({url: "checkout/sales-after/save", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),								    //  售后申请提交
	orderDelivery: (params)=> myAxios({url: "customer/order/delivery", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_login: true, code_message_show: true}),		//  确认收货
	
	// 首页
	getHomeIndex: (params)=> myAxios({url: "cms/home/index", method: 'get', params}, {message: 'loading', forbidClick: true}),																		//  首页数据
	getArticleMenu: (params)=> myAxios({url: "cms/article/menu", method: 'get', params}, {message: 'loading', forbidClick: true}),																	//  文章类目
	getArticleList: (params)=> myAxios({url: "cms/article/index", method: 'get', params}),																						                    //  文章列表
	getArticleDetail: (params)=> myAxios({url: "cms/article/detail", method: 'get', params}),																					                    //  文章详情
	
	// 商品
	getCategoryData: (params)=> myAxios({url: "catalogsearch/index/product", method: 'get', params}, {message: 'loading', forbidClick: true}),														//  商品搜索
	getCategoryIndex: (params)=> myAxios({url: "catalog/category/index", method: 'get', params}),																				                    //  类目-商品列表
	getProductIndex: (params)=> myAxios({url: "catalog/product/index", method: 'get', params}, {message: 'loading', forbidClick: true},{code_message_login: true, code_message_show: true}),		//  商品详情
	productFavorite: (params)=> myAxios({url: "catalog/product/favorite", method: 'get', params}, {}, {code_message_login: true, code_message_show: true}),						                    //  加入心愿单
	getProductfavoriteIndex: (params)=> myAxios({url: "customer/productfavorite/index", method: 'get', params}, {message: 'loading', forbidClick: true}),											//  获取心愿单列表
	
	// 定制
	vplus3dIndex: (params)=> myAxios({url: "vplus3d/customized/index-init", method: 'get', params}, {message: 'loading', forbidClick: true}),														//  列表页初始化
	vplus3dVariable: (params)=> myAxios({url: "vplus3d/customized/variable-design-list", method: 'get', params}, {message: 'loading', forbidClick: true}),											//  可变商品列表
	vplus3dVariablDdetail: (params)=> myAxios({url: "vplus3d/customized/variable-product-detail", method: 'get', params}, {message: 'loading', forbidClick: true}),								    //  可变商品详情
	vplus3dDesign: (params)=> myAxios({url: "vplus3d/customized/design-list", method: 'get', params}, {message: 'loading', forbidClick: true}),													    //  固定款商品列表
	vplus3dDesignDdetail: (params)=> myAxios({url: "vplus3d/customized/design-pruduct-detail", method: 'get', params}, {message: 'loading', forbidClick: true}),									//  固定商品详情
	
	// 购物车
	addCart: (data)=> myAxios({url: "checkout/cart/add", method: 'post', data}, {message: 'add..', forbidClick: true}, {code_message_login: true, code_message_show: true}),						//  加入购物车
	getCartIndex: (params)=> myAxios({url: "checkout/cart/index", method: 'get', params}, {message: 'loading', forbidClick: true}),																    //  购物车列表
	selectCartOne: (params)=> myAxios({url: "checkout/cart/selectone", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),									//  商品选购（单品）
	selectCartAll: (params)=> myAxios({url: "checkout/cart/selectall", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),									//  商品选购（全选）
	updateCart: (data)=> myAxios({url: "checkout/cart/updateinfo", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_login: true, code_message_show: true}),			//  更新购物车信息
	addcoupon: (data)=> myAxios({url: "checkout/cart/addcoupon", method: 'post', data}, {}, {code_message_show: true}),															                    //  使用优惠券
	cancelcoupon: (data)=> myAxios({url: "checkout/cart/cancelcoupon", method: 'post', data}, {}, {code_message_show: true}),													                    //  取消优惠券
	
	// 订单
	getOnepageIndex: (params)=> myAxios({url: "checkout/onepage/index", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_login: true, code_message_show: true}),		//  初始化订单页面信息
	onepageChangeCountry: (params)=> myAxios({url: "checkout/onepage/changecountry", method: 'get', params}, {message: 'change...', forbidClick: true}, {code_message_show: true}),				    //  更换国家
	getshippingandcartinfo: (params)=> myAxios({url: "checkout/onepage/getshippingandcartinfo", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),		//  更改地址后请求
	submitorder: (data)=> myAxios({url: "checkout/onepage/submitorder", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),								    //  提交订单
	standardStart: (data)=> myAxios({url: "payment/paypal/standard/start", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),								//  Paypal 标准支付开始（Standard Start）
	standardReview: (data)=> myAxios({url: "payment/paypal/standard/review", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),							//  Paypal 标准支付回调（Standard Review）
	paymentSuccess: (data)=> myAxios({url: "payment/success", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),											//  Payment Success
	paymentStripeStart: (data)=> myAxios({url: "payment/stripe/start", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),									//  stripe支付开始
	paymentStripeReview: (params)=> myAxios({url: "payment/stripe/review", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),								//  stripe支付回调
	aliPayStart: (data)=> myAxios({url: "payment/alipay/standard/start", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),							    //  支付宝支付开始
	aliPayReview: (params)=> myAxios({url: "payment/alipay/standard/review", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),							//  支付宝支付回调
	// wxpayStart: (data)=> myAxios({url: "payment/wxpay/standard/start", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),									//  微信扫码支付开始
	// wxpayReview: (params)=> myAxios({url: "payment/wxpay/standard/tradesuccess", method: 'get', params}),									                                                        //  微信支付成功后的回调
	wxpayStart: (data)=> myAxios({url: "payment/wxpayh5/start", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),									//  微信扫码支付开始
	wxpayReview: (data)=> myAxios({url: "payment/wxpayh5/review", method: 'post', data}, {message: 'loading', forbidClick: true}, {code_message_show: true}),									                                                        //  微信支付成功后的回调


	
	// 评论
	getReviewList: (params)=> myAxios({url: "catalog/reviewproduct/lists", method: 'get', params}),                                                                                                 //  评论列表
	getReviewInit: (params)=> myAxios({url: "catalog/reviewproduct/add", method: 'get', params}, {message: 'loading',forbidClick: true,}),                                                          //  订单评论初始化
	submitReview: (data)=> myAxios({url: "catalog/reviewproduct/submitreview", method: 'post', data}, {message: 'submit',forbidClick: true}, {code_message_show: true}),                            //  订单评论提交
	confirmVoteReview: (data)=> myAxios({url: "catalog/reviewVote/add", method: 'post', data},{}, {code_message_show: true}),                                                                       //  评论点赞/踩
	cancelVoteReview: (params)=> myAxios({url: "catalog/reviewVote/cancel", method: 'get', params},{}, {code_message_show: true}),                                                                  //  评论点赞/踩取消
	
	// Vplus3d
	getDiamondIndex: (params)=> myAxios({url: "vplus3d/diamond/luo-zuan-index", method: 'get', params}),																		                    //  获取裸钻产品列表
	getDiamondDetail: (params)=> myAxios({url: "vplus3d/diamond/detail", method: 'get', params}),																				                    //  获取单个产品信息
	getDiamondStock: (params)=> myAxios({url: "vplus3d/diamond/get-stock", method: 'get', params}),																				                    //  查询钻石数据是否有库存
	getGongliaoPrice: (data)=> myAxios({url: "vplus3d/customized/gongliao-price", method: 'post', data}),																		                    //  获取固定款价格（干预价格）
	getFixedPrice: (data)=> myAxios({url: "vplus3d/customized/fixed-price-byvplus", method: 'post', data}),																		                    //  获取固定款价格（vplus 3d）
	getVariableGongliaoPrice: (data)=> myAxios({url: "vplus3d/customized/variable-gongliao-price", method: 'post', data}),														                    //  获取可变款价格
	getFixedSuitPrice: (data)=> myAxios({url: "vplus3d/customized/fixed-suit-price", method: 'post', data}),														                                //  获取固定款价格（给套装款使用）
	
	// 设计板
	saveDesign: (data)=> myAxios({url: "vplus3d/design/save", method: 'post', data}, {message: 'save...', forbidClick: true}, {code_message_login: true, code_message_show: true}),				    //  保存设计
	delDesign: (params)=> myAxios({url: "vplus3d/design/del", method: 'get', params}, {message: 'del...', forbidClick: true}, {code_message_show: true}),											//  删除设计
	getDesignList: (params)=> myAxios({url: "vplus3d/design/list", method: 'get', params}, {message: 'loading', forbidClick: true}),															    //  设计列表
	
	// 门店预约
	getStoreIndex: (params)=> myAxios({url: "store/index/index-init", method: 'get', params}, {message: 'loading', forbidClick: true}),															    //  门店列表页初始化
	getStoreState: (params)=> myAxios({url: "store/index/get-state-by-contry-code", method: 'get', params}),																	                    //  获取省/州数据
	getStoreList: (params)=> myAxios({url: "store/index/store-list", method: 'get', params}),																					                    //  获取门店列表
	getReservationInit: (params)=> myAxios({url: "store/index/reservation-init", method: 'get', params}, {message: 'loading', forbidClick: true}),													//  预约提交初始化
	sumbitReservation: (data)=> myAxios({url: "store/index/reservation", method: 'post', data}, {message: 'sumbit...', forbidClick: true}, {code_message_show: true}),								//  预约提交
	getReservationList: (params)=> myAxios({url: "store/index/reservation-list", method: 'get', params}),																		                    //  预约记录
	getReservationDetail: (params)=> myAxios({url: "store/index/reservation-detail", method: 'get', params}, {message: 'loading', forbidClick: true}),												//  预约详情
	reservationCancel: (params)=> myAxios({url: "store/index/reservation-cancel", method: 'get', params}, {message: 'loading', forbidClick: true}, {code_message_show: true}),						//  预约取消

	// 钻石收藏
	getDiamondFavorite: (params)=> myAxios({url: "vplus3d/diamond/favorite-list", method: 'get', params}, '', {code_message_login: true, code_message_show: true}),									//  钻石收藏列表
	diamondFavoriteAdd: (data)=> myAxios({url: "vplus3d/diamond/favorite-add", method: 'post', data}, {message: 'loading', forbidClick: true}),													    //  钻石添加收藏
	diamondFavoriteCancel: (params)=> myAxios({url: "vplus3d/diamond/favorite-cancel", method: 'get', params}),																	                    //  钻石取消收藏
}

export default api