import { Locale } from 'vant';
import en from 'vant/es/locale/lang/en-US';
import zh from 'vant/es/locale/lang/zh-CN';
import tc from 'vant/es/locale/lang/zh-HK';
export default function locales(langue) {
    // console.log('langue',langue)
    if(langue == 'en'){
        Locale.use('en',en) 
    }else if(langue == 'zh'){
        Locale.use('zh',zh) 
    }else if(langue =='tc'){ 
        Locale.use('tc',tc) 
    }
}