<template>
    <div class="content">
        <!-- 订阅 -->
        <div class="subscribe flexJA flexDc">
            <div class="title">{{ $t('message.subscribeFA') }}</div>
            <div class="input flexJA flexFs">
                <input type="text" v-model="email" :placeholder="$t('message.subscribeFB')">
                <div class="btn flexJA" @click="bindSetSubscribe()">{{ $t('message.subscribeFC') }}</div>
            </div>
        </div>
        <div class="bottom">
            <van-collapse class="collapse" v-model="activeNames" accordion>
                <van-collapse-item v-for="(item, index) in bottomNav" :title="item.name" :name="index" :key="index">
                    <div class="list">
                        <div class="item" v-for="items in item.sub" @click="bindNav(item, items.id)">{{ items.name }}</div>
                    </div>
                </van-collapse-item>
            </van-collapse>
            <div class="pay" v-if="homeBase.bottom_pictures" v-for="(item, index) in homeBase.bottom_pictures">
                <div class="title flexJA">{{ index }}</div>
                <div class="list flexJA">
                    <div class="flexJA" v-for="item1  in item" @click="bindOpen(item1.url)"><van-image class="img"
                            :src="item1.image" lazy-load /></div>
                </div>
            </div>
            <div class="text flexJA">
                <div class="item" @click="jumpToPage('policy_use')">{{ $t('message.privacy') }}</div>
                <div class="item" @click="jumpToPage('cookie-policy')">{{ $t('message.CookiePolicy') }}</div>
                <div class="item" @click="jumpToPage('terms-use')">{{ $t('message.TermsOfUse') }}</div>
            </div>

            <!-- <div class="by flexJA" v-if="homeBase.verified" @click="bindOpen(homeBase.verified.info[0].url)">
                <div class="title">{{ homeBase.verified.title }}</div>
                <van-image class="img" v-if="homeBase.verified && homeBase.verified.info && homeBase.verified.info.length"
                    :src="homeBase.verified.info[0].image" lazy-load />
            </div> -->
            <div style="display: flex;margin: 0 auto;
    width: 80%;
    justify-content: space-around;
    margin-bottom: 6px;">
                <a target="_blank" href=" " style="display: flex;text-decoration:none;height:20px;line-height:20px;"><img
                        src="@/assets/images/z99.png" style="float:left;width:20px;height:20px;" />
                    <p
                        style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;font-size: 12px;">
                        粤公网安备
                        44030502010003号</p>
                </a>
            </div>
            <div class="tips" style="padding-bottom: 4px;"><a data-v-ab39a466=""
                    href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">粤ICP备2023022149号</a></div>
            <div
                style="margin: 0 auto; display: block; text-align: center; font-size: 12px;padding-bottom: 4px;color: #000;">
                聚宝元科技软件&天璟纪提供技术支持</div>
            <div class="tips flexJA">{{ homeBase.copy_right }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'TabBar',
    props: {

    },
    data() {
        return {
            activeNames: '',
            email: '',
        }
    },
    computed: {
        ...mapState(['homeBase', 'bottomNav'])
    },
    mounted() {
        console.log('homeBase', this.homeBase)
        console.log('bottomNav', this.bottomNav)
    },
    methods: {
        bindNav(item, tid,) {
            let url = `/articleList?oid=${item.id}&tid=${tid}`
            if (url == this.$route.fullPath) return;
            let arr = item.sub
            for (let i in arr) {
                if (arr[i].id == tid) {
                    this.$store.commit('gotMetaInfo', {
                        title: `JU-MART ${arr[i].title}`,
                        Keywords: arr[i].meta_keywords || 'JU-MART',
                        description: arr[i].meta_description || 'JU-MART'
                    })
                }
            }

            this.$router.push(url)
            this.activeNames = ''
            localStorage.setItem('tid', tid)
        },
        jumpToPage(type) {
            if (!type) return
            let url = '/template?clause=' + type
            this.$router.push(url)
        },
        bindOpen(url) {
            if (url) window.open(url)
        },
        // 提交订阅
        async bindSetSubscribe() {
            if (!this.email) return
            const res = await this.$api.setSubscribe({ email: this.email })
            if (res.code == 200) {
                setTimeout(() => {
                    this.$toast.success(this.$t('message.subscribeFD'))
                    this.email = ''
                }, 1000)
            }
        }
    }
}
</script>

<style scoped lang="scss">
// @font-face {
//     font-family: GillSans;
//     src: url('../assets/font/GillSans.ttc');
// }
// .content {
//     font-family: GillSans !important;
// }
.subscribe {
    padding: 20px 0;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;

    .title {
        font-size: 14px;
        font-weight: 700;
        color: #000;
        margin-bottom: 5px;
    }

    .input {
        font-size: 10px;
        // width: 185px;
        height: 30px;
        border: 1px solid #333;
        box-sizing: border-box;

        input {
            width: 165px;
            height: 26px;
            padding: 0 5px;
            box-sizing: border-box;
            background: #fff;
        }

        .btn {
            width: 55px;
            height: 30px;
            font-weight: 700;
            border-left: 1px solid #333;
        }
    }
}

.bottom {
    background: #f4e0e0;

    .collapse {
        border-top: 1px solid #000;
        margin-bottom: 30px;

        .item {
            font-size: 15px;
            padding: 5px 0;
        }
    }

    .pay {
        margin-bottom: 10px;

        .title {
            font-size: 14px;
            color: #000;
            margin-bottom: 15px;
        }

        .flexWw {
            flex-wrap: wrap;
        }

        .list {
            .img {
                width: 55px;
                height: 55px;
                margin: 0 10px 20px;
            }
        }
    }

    .text {
        margin-bottom: 35px;

        .item {
            font-size: 14px;
            color: #000;
            padding: 0 10px;
            border-right: 1px solid #999;

            &:last-child {
                border-right: none;
            }
        }
    }

    .by {
        margin-top: 8px;
        margin-bottom: 40px;

        .title {
            font-size: 14px;
            color: #000;
            margin-right: 9px;
        }

        .img {
            // width: 60px;
            // height: 25px;
        }
    }

    .tips {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        color: #000;
        padding-bottom: 22px;
    }
}

::v-deep .van-cell {
    font-size: 16px;
    color: #000;
    background: #f4e0e0;
    border-bottom: 1px solid #666;
}

::v-deep .van-cell__right-icon {
    color: #000;
    font-size: 20px;
}
</style>
