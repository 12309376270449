import messagesEn from './languages/en_US.js'
import messagesTc from './languages/tc.js'
import messagesZh from './languages/zh_CN.js'
const messages = {
	en: {
		message: messagesEn
	},
	tc: {
		message: messagesTc
	},
	zh: {
		message: messagesZh
	}
}

export default messages