import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "首页",
  //   component: () => import("@/views/index"),
  //   meta: {
  //     isKeepAlive: true,
  //   },
  // },
  {
    path: "/",
    name: "首页",
    component: () => import("@/views/home"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/register",
    name: "注册",
    component: () => import("@/views/register"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/forget",
    name: "忘记密码",
    component: () => import("@/views/forget"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/myCenter",
    name: "个人中心",
    component: () => import("@/views/myCenter"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/articleList",
    name: "文章列表",
    component: () => import("@/views/articleList"),
    meta: {
      isKeepAlive: true,
      title: 'articleList'
    },
  },
  {
    path: "/articleDetails",
    name: "文章详情",
    component: () => import("@/views/articleDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/problem",
    name: "常见问题",
    component: () => import("@/views/problem"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/set",
    name: "设置",
    component: () => import("@/views/set"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/search",
    name: "搜索",
    component: () => import("@/views/search"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/searchResult",
    name: "搜索结果",
    component: () => import("@/views/searchResult"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/wishList",
    name: "愿望清单",
    component: () => import("@/views/wishList"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/design",
    name: "设计版",
    component: () => import("@/views/design"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/account",
    name: "账户",
    component: () => import("@/views/account"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/myDetails",
    name: "我的信息",
    component: () => import("@/views/myDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/order",
    name: "订单",
    component: () => import("@/views/order"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/myAppointment",
    name: "我的预约",
    component: () => import("@/views/myAppointment"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/appointmentDetails",
    name: "预约详情",
    component: () => import("@/views/appointmentDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/orderDetails",
    name: "订单详情",
    component: () => import("@/views/orderDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/applicat",
    name: "申请退款",
    component: () => import("@/views/applicat"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/refund",
    name: "退款详情",
    component: () => import("@/views/refund"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/goodsList",
    name: "goodsList",
    component: () => import("@/views/goodsList"),
    meta: {
      isKeepAlive: true,
      title: 'goodsList',
      keepAlive: true,
    },
  },
  {
    path: "/goodsDetails",
    name: "goodsDetails",
    component: () => import("@/views/goodsDetails"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/diamondList",
    name: "钻石列表",
    component: () => import("@/views/diamondList"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/diamondDetails",
    name: "钻石详情",
    component: () => import("@/views/diamondDetails"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/reviewList",
    name: "评论列表",
    component: () => import("@/views/reviewList"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/writeReview",
    name: "写评论",
    component: () => import("@/views/writeReview"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/notLogin",
    name: "未登录",
    component: () => import("@/views/notLogin"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/paymentInfo",
    name: "付款信息",
    component: () => import("@/views/paymentInfo"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/storeService",
    name: "门店服务",
    component: () => import("@/views/storeService"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/appointmentShop",
    name: "门店预约",
    component: () => import("@/views/appointmentShop"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/appointmentSuccess",
    name: "预约成功",
    component: () => import("@/views/appointmentSuccess"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/cart",
    name: "购物车",
    component: () => import("@/views/cart"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/paycallback",
    name: "paypal支付回调",
    component: () => import("@/views/paycallback"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/stripeback",
    name: "stripe支付回调",
    component: () => import("@/views/stripeback"),
    meta: {
      isKeepAlive: true,
    },
  },
  {
    path: "/logisticsInformation",
    name: "物流信息",
    component: () => import("@/views/logisticsInformation"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/template",
    name: "模板",
    component: () => import("@/views/template"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/filter",
    name: "筛选",
    component: () => import("@/views/filter"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/guide",
    name: "尺寸指南",
    component: () => import("@/views/guide"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/variableDetails",
    name: "variableDetails",
    component: () => import("@/views/variableDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/fixedDetails",
    name: "fixedDetails",
    component: () => import("@/views/fixedDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/customDetails",
    name: "customDetails",
    component: () => import("@/views/customDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/suitDetails",
    name: "suitDetails",
    component: () => import("@/views/suitDetails"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "/tryItOn",
    name: "试戴",
    component: () => import("@/views/tryItOn"),
    meta: {
      isKeepAlive: false,
    },
  },
  {
    path: "*",
    name: "NotFun",
    component: () => import("@/views/404"),
    meta: {
      isKeepAlive: true,
    },
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
