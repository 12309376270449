const messagesZh = {
	already_got_an_account: '已經有賬戶了?',
	register: '注冊',
	login: '登錄',
	signUp: '注冊',
	email: '郵箱',
	getCode: '獲取驗證碼',
	securityCode: '驗證碼',
	password: '密碼',
	confirmPassword: '確認密碼',
	please: '請輸入',
	pleases: '請選擇',
	terms: '條款及條件',
	privacy: '隱私政策',
	Already: '已經注冊?',
	and: '和',
	agr1: '建立帳戶即代表您同意我們根據 LightCentery 的',
	agr2: '如果您不希望繼續收到我們的推廣資料，請勾選此框',
	createAccount: '創建壹個帳戶',
	please_check_the: '請勾選',
	LOG_IN: '登錄',
	login_text1: '登錄可以加快結算速度，保存購買記錄',
	login_text2: '創建您自己的帳戶，以便您可以隨時查看訂單信息和跟蹤訂單狀態',
	Forgot_password: '忘記密碼了 ?',
	CREATE_AN_ACCOUNT: '創建壹個帳戶',
	forgot_password: '忘記了密碼?',
	forget_text1: '若要重置密碼，請輸入手機號碼',
	reset_password: '重置密碼',
	confirm_the_new_password: '確認新密碼',
	proceed_to_Log_In: '繼續登錄',
	confirm: '确认',
	log_in: '登錄',
	set_title: '改變地址,語言',
	set_text: '請選擇你的送貨目的地及貨幣',
	Countries: '國家',
	Currency: '貨幣',
	Language: '語言',
	ShippingTo: '運往',
	CONFIRM: '確認',
	Metal: '金屬',
	Size: '尺寸',
	Additional_Certification: '附加證書',
	Amount: '數量',
	addCart: '添加購物車',
	CheckOut: '結賬',
	Guide: '尺寸選擇指南',
	serviceA: '尋找區內的LightCentery線下門店,預約私人的珠寶顧問服務',
	serviceB: '查找',
	reviews: '評論',
	write_a_review: '寫評論',
	ViewMore: '查看更多',
	Recommend: '向您推薦',
	Collocation: '搭配組合',
	YouLike: '猜你也喜歡',
	Details: '詳情',
	add_success: '加入成功',
	cancel: '取消',
	Inch: '英寸',
	Sort: '排序',
	Filter: '篩選',
	NoLog: '請先登錄',
	PartnerDesigners: '合作夥伴設計師',
	CustomDesignStudio: '自定義設計工作室',
	DesignReady: '設計好了',
	love_text: '把你愛的光芒放在命運的紅線裏讓我們的故事與眾不同',
	Browse: '浏覽',
	best: '我們的暢銷貨',
	bestsellers: '熱銷單品',
	diamond_btn: '選購鑽石',
	CustomCategories: '自定義分類',
	Start1: '從戒指款式開始',
	Start2: '從鑽石開始',
	JM1: 'LightCentery 線下門店',
	JM2: '他們都選擇了 LightCentery',
	ContactUs: '聯絡我們',
	OnlineConsultant: '線上小助手',
	Search: '搜索',
	ShoppingCart: '購物車',
	cartEmpty: '你的購物車是空的',
	cart_text: '當前的購物車有',
	works: '作品',
	ALL: '全部',
	pleaseSelect: '請選擇',
	Quantity: '數量',
	Total: '總計',
	OrderInformation: '訂單信息',
	Items: '件',
	Subtotal: '小計',
	PromotionCode: '優惠碼',
	Promo_Price: '優惠金額',
	Promo_Code: '優惠碼',
	promocode: '輸入優惠碼獲得折扣',
	confirm: '確認',
	Confirm: '確認',
	SettleAccounts: '請選擇商品後再結賬',
	Accountinformation: '賬戶信息',
	accountOrderA: '登錄帳戶將用於跟蹤您的訂單狀態',
	accountOrderB: '以下信息將用於跟蹤您的訂單狀態',
	accountOrderC: '訂單一概不得運往郵政信箱。',
	accountOrderD: '請提供一個送件時將有人簽收的街頭地址。',
	Shippinginformation: '配送信息',
	StandardLogistics: '標准物流',
	PickStore: '郵寄到門店',
	Theeditor: '編輯',
	Edit: '編輯',
	Title: '稱呼',
	FirstName: '名稱',
	LastName: '姓',
	PhoneNumber: '手機號碼',
	StateProvince: '州/省',
	PostalCode: '郵編',
	AddressLine1: '詳細地址 1',
	AddressLine2: '詳細地址 2',
	PaymentMethod: '支付方式',
	Postscript: '附言',
	PostscriptFA: '如果您還有其他要求，請在這裏填寫',
	SpecialRequirement: '特殊要求',
	PaymentAgrFA: '本人明白並接受有關',
	PaymentAgrFB: '條款與條件',
	PaymentAgrFC: '包括',
	PaymentAgrFD: '付款',
	PaymentAgrFE: '退貨政策',
	UnitPrice: '單價',
	ShippingAndInsurance: '運輸和保險',
	TaxAndDuty: '稅收和關稅',
	gender0: '保密',
	gender1: '先生',
	gender2: '女士',
	PaymentType: '請選擇支付方式',
	MyDetails: '我的信息',
	OrderStatus: '我的訂單',
	MyAppointment: '我的預約',
	Account: '我的賬號',
	SignOut: '退出登錄',
	Birthday: '生日',
	RelationshipStatus: '感情狀態',
	Unmarried: '未婚',
	Married: '已婚',
	Engaged: '訂婚',
	anniversary: '你們的結婚紀念日是什麽時候?',
	PleaseDate: '請選擇日期',
	complete: '完成',
	cancel: '取消',
	YourEmail: '您的郵箱',
	YourPassword: '您的密碼',
	YourNewPassword: '您的新密碼',
	reconfirm: '再次確認密碼',
	NewNumber: '新號碼',
	PasswordTips: '輸入以下信息來更改密碼',
	WishList: '願望清單',
	have: '你目前有',
	WishLists: '願望清單',
	listEmpty: '你的願望清單是空的',
	OrderNo: '訂單號',
	Complete: '現貨',
	details: '詳情',
	loading: '加載中...',
	MyOrder: '我的訂單',
	MyApplication: '我的申請',
	ShippingInformation: '配送信息',
	CommodityInformation: '商品信息',
	Create: '創建時間',
	Payment: '支付時間',
	OrderComfirmed: '審核時間',
	ShippedDate: '發貨時間',
	CompleteDate: '完成時間',
	Refund: '退款時間',
	LogisticsInformation: '物流信息',
	ConfirmReceipt: '確認收貨',
	ProceedToPayment: '繼續付款',
	PaymentSuccess: '付款成功',
	PaymentFailure: '付款失敗',
	ContinuePurchase: '繼續購買',
	return: '返回',
	PaymentFA: '我們的客戶服務團隊已經收到了您的訂單，目前正在審核。',
	PaymentFB: '付款失敗，請重新付款。',
	AppointmentDetails: '預約詳情',
	StoreService: '門店服務',
	BusinessHours: '營業時間',
	Change: '改變',
	shopFA: '找壹家離我近的商店',
	shopFB: '預約',
	Location: '位置',
	CityRegion: '城市區域',
	SelectStore: '選擇門店',
	VisitingAppointment: '去預約',
	appointmentFA: '選擇您喜歡的商店，在友好的員工的支持下享受定製設計體驗。填妥表格並遞交，我們會以電話或電郵與您聯絡確認。',
	appointmentFB: '你想去哪家店',
	appointmentFC: '你想要的聯系方式',
	appointmentFD: '請詳細說明我們可以如何幫助你。',
	appointmentFE: '你已經有想法了嗎?(可選)',
	appointmentFF: '我們需要收集及處理你的個人資料，以回應你的要求。如果您使用本服務，您已閱讀並接受本',
	appointmentFG: '我們會與您聯系',
	appointmentFH: '我們已收到您的請求,並將在48小時內與您聯系,安排店內預約。',
	appointmentFI: '您的選擇',
	appointmentFJ: '您在 “{count}” 的預約被批准。具體情況如下:',
	appointmentFK: '勾選此方框，即表示我同意本',
	appointmentFL: ',並同意將所提供的信息用於LightCentery。',
	PrivacyTerms: '隱私條款。',
	MyDesign: '我的設計',
	Choose: '選擇',
	AppointmentTime: '預約日期及時間',
	HomePage: '首頁',
	Purpose: '目的',
	// AppointmentTime: '預約時間',
	IChooseThe: '我選擇的',
	CancelSuccess: '取消成功',
	Category: '分類',
	Clear: '清空',
	Found: '發現',
	results: '結果',
	PleaseKeywords: '請輸入關鍵字',
	recommend: '推薦',
	SearchFA: '用自己的方式設計專屬的訂婚戒指',
	goto: '去',
	prompt: '提示',
	promptFA: '您還沒有登錄。您想登錄嗎?',
	DeleteRecord: '刪除記錄?',
	Share: '分享',
	resultsFA: '"{count}" 沒有找到任何結果',
	resultsFB: '請重新搜索',
	resultsFC: '沒有找到任何結果',
	GuideFA: '拿壹根不可拉伸的繩子、絲帶、線或壹張薄紙。把它舒適地繞在無名指或其他手指上，靠近指關節',
	GuideFB: '仔細地用鋼筆在琴弦重疊的地方做記號。為了達到最好的准確性，盡可能做最小的標記',
	GuideFC: '打開標簽的長度。用尺子測量毫米或英寸。在下面輸入你的測量值',
	GuideFE: '輸入你的尺寸',
	GuideFF: '您想把測量好的尺寸存到您的賬戶中嗎?',
	GuideFG: '是的，我想保存它',
	ScaleUnits: '標度單位',
	Start: '開始',
	MeasureResult: '測量結果',
	MeasureResultFB: '您的尺碼是',
	send: '獨特而有品位的物品讓你送出你的，送給你的',
	DataEmpty: '暫無數據',
	RefundFA: '申請退貨/退款',
	RefundFB: '收到物品後(7)天內生效，定製物品除外。提交您的申請，我們的客戶代表將回複您的後續行動。',
	RefundFC: '服務類型',
	RefundFD: '退換貨原因',
	RefundFE: '詳細描述',
	RefundFF: '圖片資料',
	RefundFG: '為了幫助我們更好的解決這個問題，請上傳圖片',
	RefundFH: '申請退款',
	RefundFI: '申請時間',
	RefundFJ: '退回類型',
	RefundFK: '退款金額',
	refund: '(退款)',
	Remark: '備注',
	submit: '提交',
	salesAfterFA: '在審查中',
	salesAfterFB: '我們的客戶服務團隊已經收到您的退款請求，目前正在為您審查它',
	salesAfterFC: '退款被拒絕',
	salesAfterFD: '很抱歉，您的請求被拒絕了。如果您有任何問題，請聯系我們的客服代表。我們希望很快再見到您！',
	salesAfterFE: '退款成功',
	salesAfterFF: '您的退款已被批淮，所要求的金額已通過您原來的支付方式返回給您。請注意查收',
	salesAfterFG: '缺貨',
	salesAfterFH: '我們非常抱歉。每顆磚石都是獨壹無二,您選擇的鑽石已經斷貨,我們已經選擇了幾顆價格相識,4C的鑽石給您替換,仍然不能滿足您的要求,我們將通過您原來的付款方式將金額原路退回。谢谢您的理解,希望很快能见到您!',
	salesAfterFI: '商家回復：',
	salesAfterFJ: '審查通過',
	salesAfterFK: '審查通過,等待商家退款',
	salesAfterFL: '退款已取消',
	salesAfterFM: '退貨不接受',
	salesAfterFN: '可能商品影響第二次銷售',
	PleaseSize: '請選擇尺寸大小',
	arm: '主件',
	accessories: '配件',
	styles: '款式',
	material: '材質',
	diamond: '鉆石',
	Engraving: '刻字',
	EngravingFA: '最多12個字符,6個漢字',
	EngravingFB: '該商品不支持雕刻',
	EngravingFC: '字母數字組合,長度最多12個字符',
	Cost: '成本',
	DesignFolder: '設計文件夾',
	buy: '購買',
	Setting: '設置',
	Diamond: '鑽石',
	ProductFailure: '商品失效',
	ProductDetails: '產品描述',
	Latest: '最新',
	Price: '價格',
	Carat: '克拉',
	Colour: '顏色',
	Cut: '切工',
	Clarity: '淨度',
	Polish: '抛光',
	Symmetry: '對稱',
	Fluor: '熒光',
	replace: '替換',
	ClearFilters: '清空篩選',
	points: '分',
	Choice: '選擇',
	NoComments: '暫無評論',
	Score: '得分',
	PleaseContent: '請輸入內容',
	PostAnonymously: '匿名帖子',
	PickupCode: '提取碼',
	NoDiamonds: '沒有找到符合條件的鑽石',
	SubmitSuccess: '提交成功',
	ReviewFA: '你已評論',
	Edit: '編輯',
	AddCart: '添加到購物車',
	SaveName: '保存的名字',
	SaveDesign: '保存到我的設計',
	Existing: '現成商品',
	Custom: '定製商品',
	Select: '選擇',
	EssentialInformation: '基本信息',
	MyDesign: '我的設計',
	designFA: '你目前已經設計了',
	designFB: '件作品',
	designFC: "你還沒有開始妳的設計。",
	ViewAll: '查看全部',
	CompareDesigns: '比較設計',
	Comparison: '比較',
	WEACCEPT: '我們接受以下付款方式',
	or: '或',
	SeeMore: '查看更多',
	DiscoverMore: '發現更多的',
	HomeFA: '第壹次訂購可享受10%的優惠',
	HomeFB: '第壹個體驗我們的3D定製和虛擬試穿',
	HomeFC: "暢銷單品",
	HomeFD: '全球免運費',
	pleaseFA: '請選擇稱呼',
	pleaseFB: '請輸入名稱',
	pleaseFC: '请输入姓',
	pleaseFD: '請輸入姓',
	pleaseFE: '請輸入手機號碼',
	pleaseFF: '請選擇預約時間',
	pleaseFG: '請勾選隱私條款',
	Customization: '體驗定製化功能',
	Shopping: '回到購物',
	RefundOrReturn: '退款/退貨',
	uploading: '上傳中...',
	done: '上傳完成',
	changeFA: '換指',
	changeFB: '換手',
	photoFA: '拍照',
	photoFB: '重拍',
	flip: '翻轉',
	mirror: '鏡像',
	share: '分享',
	back: '收起',
	TryOn1: '試戴',
	TryOn2: '我要試戴',
	TouchFA: '橫向-旋轉',
	TouchFB: '縱向-移動',
	TouchFC: '雙指-縮放',
	shareFB: '長按分享',
	CookiePolicy: 'Cookie 政策',
	TermsOfUse: '使用條款',
	taxes: '**** 不含稅 ****',
	// subscribeFA: '獲取獨家報價和新聞',
	subscribeFA: '獲得專享優惠和新消息',
	subscribeFB: '請輸入您的電話號碼',
	subscribeFC: '登記',
	subscribeFD: '感謝您的訂閱！',
	Cancel: '取消',
	Search: '搜索',
	EditSuccess: '編輯成功',
	Hi: '嗨',
	ChooseFa: '選擇這顆鉆石',
	ChooseFb: '請選擇鉆石',
	Certification: '資質證書',
	ReportNumber: '編號',
	Shape: '形狀',
	Depth: '深度',
	Table: 'Table %',
	Measurements: '測量值',
	StockNumber: '庫存編號',
	Location: '地址',
	StartWithDiamonds: '從鉆石開始',
	SETTING: '設置',
	DiamondGemstone: '鉆石/寶石',
	RecentlyViewed: '最近瀏覽',
	NotFun: '產品不存在或已下架',
	low: '最低',
	high: '最高',
	BackHome: '返回首頁',



	language: '語言',
	currency: '貨幣',
	sort: '排序',
	filter: '過濾',
	description: '描述',
	cart: '購物車',
	subtotal: '小計',
	first_name: '名',
	last_name: '姓',
	telephone: '電話',
	street: '街道',
	country: '國家',
	state: '省',
	city: '城市',
	password: '密碼',
	account: '賬戶',
	home: '首頁',
	sign_in: '登錄',
	logout: '退出登錄',
	name: '名字',
	address: '地址',
	date: '日期',
	submit: '提交',
	review: '評論',
	rate: '評星',
	name: '姓名',
	accessories: '配件',
	gem: '寶石',
	price: '價格',
	Appointment: '我的預約',
	Account: '賬戶信息',

	Waiting: '等待付款',
	WaitingFA: '您的訂單已創建,請繼續完成付款;如未能在60分鍾內付款,訂單將自動取消。',
	WaitingFB: '支付處理中',
	WaitingFC: '付款成功',
	WaitingFD: '欺詐',
	InReview: '在審查中',
	InReviewFA: '我們的客戶服務團隊已經收到了您的訂單，目前正在為您審查中。',
	InReviewFB: '請耐心等待',
	wSend: '訂單已確認',
	wSendFA: '客服已經確認您的訂單。我們會盡快安排訂單處理並發貨給您。',
	wGoods: '您的訂單已經發貨',
	wGoodsFA: '請檢查發貨人的跟蹤號查看物流狀態。',
	wreceived: '您的訂單已收貨',
	wEnd: '您的訂單完成了',
	wEndFA: '簽署並接收訂單。感謝您在LightCentery購物,希望您喜歡您的新珠寶！請給我們您的意見和建議。我們希望很快再見到您！',
	wExpired: '您的訂單已取消',
	wExpiredFA: '您的訂單已取消',
	wExpiredFB: '你要走了，我們很難過!您的付款逾期，訂單被取消。我們期待很快再見到你!',
	wExpiredFC: '您的付款過期了',
}
export default messagesZh