import Vue from 'vue'
import Vuex from 'vuex'
import $api from '@/utils/api'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: '',
		config: {
			countries: '',
			countriesCode: '',
			language: '',
			languageCode: '',
			currency: '',
			currencyCode: ''
		},  // 语言，货币，地区
		topNav: [],
		langList: [],
		currencyList: [],
		countryList: [],
		homeBase: {},
		userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
		bottomNav: {},
		countryArr: [],
		stateArr: [],
		cartList: [],
		cartInfo: {},
		currency: {},
		favoriteList: [],
		addressList: [],
		designData: {},
		designList: [],
		recommend: {},
		metaInfo: {
			title: process.env.VUE_APP_ENV,
			Keywords: process.env.VUE_APP_ENV,
			description: process.env.VUE_APP_ENV
		},
		diamond: {},
		isMain: null,
		histroy: JSON.parse(localStorage.getItem('histroy')) || [], // 存储浏览历史
		aliveRoutes: [''],
	},
	mutations: {
		exit(state) {
			state.token = '';
			state.cartList = [];
			state.designList = [];
			state.favoriteList = [];
			state.addressList = [];
		},
		gotMetaInfo(state, payload) {
			console.log('meta',payload)
			state.metaInfo = payload;
		},
		gotuserInfo(state, payload){
			state.userInfo = payload;
		},
		gotToken(state, payload){
			state.token = localStorage.getItem('token') || '';
			if(state.token) {
				this.dispatch('getAddressIndex');
				this.dispatch('getCartList');
				this.dispatch('getFavorite');
				this.dispatch('getDesignList');
				this.dispatch('getAddressList');
			}
			this.dispatch('getKeywordsRecommend');
		},
		gotHomeBase(state, payload) {
			// console.log('基本信息',payload)
			payload.taxes_duties = payload.taxes_duties ? payload.taxes_duties.replace(/\n/g, '<br/>') : '';
			state.homeBase = payload;
			state.topNav = payload.top_nav;
			state.bottomNav = payload.bottom_nav;
			state.langList = payload.lang.langList;
			state.currencyList = payload.currency.currencyList;
			state.countryList = payload.country;
			this.commit('gotLanguage', payload.lang.currentLang);
			this.commit('gotCurrency', payload.currency.currentCurrency);
			this.commit('gotCountries', payload.country);
		},
		gotLanguage(state, payload) {
			let o = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')): '';
			for(let i in state.langList) {
				if(state.langList[i].code == (o.languageCode || payload)) {
					state.config.languageCode = state.langList[i].code;
					state.config.language = state.langList[i].languageName;
				}
			}
		},
		gotCurrency(state, payload) {
			let o = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')): '';
			for(let i in state.currencyList) {
				if(state.currencyList[i].code == (o.currencyCode || payload)) {
					// console.log(state.config)
					state.config.currencyCode = state.currencyList[i].code;
					state.config.currency = state.currencyList[i].code + '' + state.currencyList[i].symbol;
				}
			}
		},
		gotCountries(state, payload) {
			let o = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')): '';
			for(let i in state.countryList) {
				if(state.countryList[i].country_symbol == o.countriesCode) {
					state.config.countriesCode = state.countryList[i].country_symbol;
					state.config.countries = state.countryList[i].country_code;
				}
			}
			// console.log('payload', o.countriesCode)
		},
		gotAddressIndex(state, payload) {
			state.stateArr = payload.address.stateArr;
			state.countryArr = Object.keys(payload.address.countryArr).map(key => {
                return {
                    code: key,
                    name: payload.address.countryArr[key]
                }
            })
			// state.countryArr = payload.address.countryArr;
			// this.commit('gotCountries', payload);
			// console.log('addressCountry',state.countryArr)
		},
		gotFavorite(state, payload){
			state.favoriteList = payload;
		},
		gotDesignList(state, payload){
			state.designData = payload;
			let coll = payload.coll;
			for(let i in coll) {
				// if(!coll[i].hasOwnProperty('compare')) coll[i].compare = false;
				if(typeof coll[i].info == 'string') coll[i].info = JSON.parse(coll[i].info);
				if(coll[i].info) {
					if(coll[i].info.sku && coll[i].info.skuLanguages){
						coll[i].info.sku = coll[i].info.skuLanguages[state.config.languageCode];
					}
				}
			}
			// console.log('获取设计版列表',coll)
			state.designList = coll;
		},
		gotCartList(state, payload){
			// state.currency = payload.currency;
			// state.cartInfo = payload.cart_info || {};
			// let list =  payload.cart_info ? payload.cart_info.products : [];
			// for(let i in list) {
			// 	if(list[i].customized_product_item) list[i].customized_product_item = JSON.parse(list[i].customized_product_item);
			// }
			// console.log('gotCartList',list)
			// state.cartList = list;
			state.currency = payload.currency;
			state.cartInfo = payload.cart_info || {};
			let list =  payload.cart_info ? payload.cart_info.products : [];
			for(let i in list) {
				if(list[i].customized_product_item) {
					list[i].customized_product_item = JSON.parse(list[i].customized_product_item);
					if(list[i].customized_product_item.sku && list[i].customized_product_item.skuLanguages){
						list[i].customized_product_item.sku = list[i].customized_product_item.skuLanguages[state.config.languageCode];
					}
				}
			}
			state.cartList = list;
		},
		gotAddressList(state, payload) {
			// console.log(state, payload)
			state.addressList = payload;
		},
		gotKeywordsRecommend(state, payload) {
			state.recommend = payload;
		},
		gotDiamond(state, payload) {
			state.diamond = payload;
		},
		gotMain(state, payload) {
			state.isMain = payload;
		},
		delMain(state, payload) {
			state.isMain = null;
		},
		getHistroy(state, histroy) {
			// 本地商品浏览记录
			const res= new Map()
			state.histroy.unshift(histroy)
			state.histroy = state.histroy.filter((item)=> !res.has(item.product_id) && res.set(item.product_id,1))
			state.histroy.splice(5)
			localStorage.setItem('histroy', JSON.stringify(state.histroy))
		},
		gotAliveRoutes(state, payload) {
			state.aliveRoutes = payload
		}
	},
	actions: {
		getAliveRoutes({commit}, payload) {
			commit('gotAliveRoutes',payload)
		},
		getuserInfo({ commit, dispatch }){
			$api.getUserInfo().then(res=>{
				commit('gotuserInfo', res.data  || {});
				localStorage.setItem('userInfo', res.data ? JSON.stringify(res.data) : '{}');
			})
		},
		getHomeBase({ commit, dispatch }) {
			//获取页面公共数据
			$api.getHomeBase().then(res=>{
				// console.log(res)
				commit('gotHomeBase', res.data);
				localStorage.setItem('homeBase', JSON.stringify(res.data));
                // console.log('获取页面公共数据',res)
			})
		},
		getAddressIndex({ commit, dispatch }) {
			//编辑地址初始化
			$api.editAddressIndex().then(res=>{
				// console.log(res)
				commit('gotAddressIndex', res.data);
				localStorage.setItem('countries', JSON.stringify(res.data));
			})
		},
		getFavorite({ commit, dispatch }) {
			//获取心愿单列表
			$api.getProductfavoriteIndex().then(res=>{
				commit('gotFavorite', res.data.productList)
				// console.log('获取心愿单列表',res.data.productList)
			})
		},
		getDesignList({ commit, dispatch }) {
			//获取设计版列表
			$api.getDesignList().then(res=>{
				// console.log(res)
				commit('gotDesignList', res.data)
			})
		},
		getCartList({ commit, dispatch }) {
			//购物车列表
			$api.getCartIndex().then(res=>{
				// console.log('购物车列表',res)
				commit('gotCartList', res.data)
			})
		},
		getAddressList({ commit, dispatch }) {
			//地址列表
			$api.getAddressIndex().then(res=>{
				// console.log('地址列表',res)
				commit('gotAddressList', res.data.addressList)
			})
		},
		getKeywordsRecommend({ commit, dispatch }) {
			// 获取推荐关键词
			$api.getCategoryData({p:1}).then(res=>{
				commit('gotKeywordsRecommend', res.data.keywords_recommend)
				// console.log('获取推荐关键词',res)
			})
		}
		
	},
	getters: {},
	modules: {}
})
