<template>
    <div class="content">
        <div class="nav flexJA">
            <div @click="jumpToPage()"><img src="@/assets/images/21.png" alt=""></div>
            <!-- <div class="title">{{title}}</div> -->
            <div class="title banLine" v-html="title"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavigationTitle',
    props: ['title'],
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        jumpToPage() {
            if(this.title == this.$t('message.MyOrder')) {
                this.$router.push('/order')
            }else {
                this.$router.go(-1)
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 375px;
        height: 44px;
        border-bottom: 2px solid #F4F5F7;
        background: #ffffff;
        z-index: 9999;
        img {
            position: absolute;
            top: 12px;
            left: 15px;
            width: 20px;
            height: 20px;
        }
        .title {
            width: 260px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            text-align: center;
        }
        .title::v-deep * {
            width: 260px;
            font-size: 16px !important;
            font-weight: bold !important;
            color: #333333 !important;
            margin: 0 !important;
            text-indent: 0 !important;
            overflow: hidden; 
            text-overflow: ellipsis; 
            white-space: nowrap;
            text-align: center !important;
        }
    }
</style>
